import * as React from 'react';
import './App.scss';
import Header from './components/layout/Header';
import Landing from './components/Landing';
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import ls from 'localstorage-slim';
import Home from './components/Home';
import Hora from './components/Hora';
import Canal from './components/Canal';
import Iungo from './components/Iungo';
import Footer from './components/layout/Footer';

ls.config.encrypt = true;
let config = {
  // Development
  // baseURL: 'http://localhost:8090'
  // Live
  baseURL: 'https://mcback.compile-studio.com/'
};
let client = axios.create(config);

let paisSuper = 'gt';
let meta = {
  gt: 500000,
  hn: 27500,
  sv: 62600,
  ni: 28000,
};
const anual = {
  gt: [
    {
      anio: '2009',
      cantidad: 300508
    },
    {
      anio: '2010',
      cantidad: 358255
    },
    {
      anio: '2011',
      cantidad: 438513
    },
    {
      anio: '2012',
      cantidad: 414767
    },
    {
      anio: '2013',
      cantidad: 436449
    },
    {
      anio: '2014',
      cantidad: 423617
    },
    {
      anio: '2015',
      cantidad: 494446
    },
    {
      anio: '2016',
      cantidad: 429065
    },
    {
      anio: '2017',
      cantidad: 408138
    },
    {
      anio: '2018',
      cantidad: 442922
    },
    {
      anio: '2019',
      cantidad: 497149
    },
    {
      anio: '2021',
      cantidad: 460705
    },
    {
      anio: '2022',
      cantidad: 453015
    },
    {
      anio: '2023',
      cantidad: 0
    },
  ],
  hn: [
    {
      anio: '2011',
      cantidad: 5067
    },
    {
      anio: '2012',
      cantidad: 4464
    },
    {
      anio: '2014',
      cantidad: 4261
    },
    {
      anio: '2015',
      cantidad: 4429
    },
    {
      anio: '2016',
      cantidad: 6003
    },
    {
      anio: '2017',
      cantidad: 7476
    },
    {
      anio: '2018',
      cantidad: 8579
    },
    {
      anio: '2019',
      cantidad: 10371
    },
    {
      anio: '2021',
      cantidad: 23719
    },
    {
      anio: '2022',
      cantidad: 24028,
    },
    {
      anio: '2023',
      cantidad: 0
    },
  ],
  sv: [
    {
      anio: '2011',
      cantidad: 9634
    },
    {
      anio: '2012',
      cantidad: 13614
    },
    {
      anio: '2013',
      cantidad: 15055
    },
    {
      anio: '2014',
      cantidad: 17574
    },
    {
      anio: '2015',
      cantidad: 16437
    },
    {
      anio: '2016',
      cantidad: 17563
    },
    {
      anio: '2017',
      cantidad: 30284
    },
    {
      anio: '2018',
      cantidad: 32472
    },
    {
      anio: '2019',
      cantidad: 35670
    },
    {
      anio: '2021',
      cantidad: 71976
    },
    {
      anio: '2022',
      cantidad: 55637
    },
    {
      anio: '2023',
      cantidad: 0
    },
  ],
  ni: [
    {
      anio: '2012',
      cantidad: 10799
    },
    {
      anio: '2013',
      cantidad: 12765
    },
    {
      anio: '2014',
      cantidad: 11868
    },
    {
      anio: '2015',
      cantidad: 12622
    },
    {
      anio: '20156',
      cantidad: 14397
    },
    {
      anio: '2017',
      cantidad: 15626
    },
    {
      anio: '2022',
      cantidad: 22820
    },
    {
      anio: '2023',
      cantidad: 0
    },
  ]
};

// const canales = [
//   {
//     anio: 'Anterior',
//     pv: 136000,
//     of: 10000,
//     vd: 436000
//   },
//   {
//     anio: '2022',
//     pv: 136000,
//     of: 10000,
//     vd: 436000
//   }
// ];


const App = () => {
  const [logged, setLogged] = useState(false);
  const [updated, setUpdated] = useState(new Date());
  const [pais, setPais] = useState({ value: 'gt', label: 'Guatemala' });
  const [restaurantes, setRestaurantes] = useState({
    gt: null,
    hn: null,
    sv: null,
    ni: null,
  });
  const [restaurantesAreas, setRestaurantesAreas] = useState({
    gt: null,
    hn: null,
    sv: null,
    ni: null,
  });
  const [horas, setHoras] = useState({
    gt: null,
    hn: null,
    sv: null,
    ni: null,
  });
  const [preventa, setPreventa] = useState({
    gt: null,
    hn: null,
    sv: null,
    ni: null,
  });
  const [granTotal, setGranTotal] = useState({
    gt: null,
    hn: null,
    sv: null,
    ni: null,
  });
  const [canales, setCanales] = useState({
    gt: null,
    hn: null,
    sv: null,
    ni: null,
  });
  const [ventasDelivery, setVentasDelivery] = useState(null);

  const changePais = (e) => {
    setPais(e);
    paisSuper = e.value;
  }

  const logout = () => {
    Swal.fire({
      title: '¿Seguro que desea Salir?',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: `No`,
      icon: 'warning'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setLogged(false);
        ls.remove('MCDI');
        window.location.href = '/';
      }
    });
  }

  const signIn = (email, pass) => {
    if (
      email === '' ||
      pass === ''
    ) {
      Swal.fire({
        title: '¡Error!',
        text: 'Todos los campos son obligatorios',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    } else {
      let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (email.match(validRegex)) {
        // Force logout for all users
        // if (
        //   email !== 'yuukan@gmail.com' &&
        //   email !== 'marco.gonzalez@iungo.co'
        // ) {
        //   Swal.fire({
        //     title: 'Atención!',
        //     text: 'Esta sesión ha expirado, para mayor información contacte al administrador del sistema. Muchas gracias.',
        //     icon: 'info',
        //     confirmButtonText: 'Ok'
        //   });
        //   return;
        // }
        client.post(
          '/user/login'
          , {
            email: email,
            pass: pass
          }
        ).then((response) => {
          ls.set('MCDI', JSON.stringify(response.data));
          setLogged(true);
          // Get the pais
          let paises = response.data.pais.split(',');
          paisSuper = paises[0];

          let options = [
            { value: 'gt', label: 'Guatemala' },
            { value: 'hn', label: 'Honduras' },
            { value: 'sv', label: 'El Salvador' },
            { value: 'ni', label: 'Nicaragua' }
          ];
          let pais = options.find(element => element.value === paises[0]);
          setPais(pais);

          getInfo(paisSuper);
        }).catch((error) => {
          Swal.fire({
            title: 'Error!',
            text: error.response.data.msg,
            icon: 'error',
            confirmButtonText: 'Ok'
          });
        });
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Please enter a valid email',
          icon: 'error',
          confirmButtonText: 'Ok'
        });
      }
    }
  }

  // Reorder array of restaurants
  const shuffleArray = (array, pais) => {
    let arr = [...array];
    // Sort array desc
    arr.sort((a, b) => parseInt(b.total) - parseInt(a.total));

    let res = { ...restaurantes };
    res[pais] = arr;

    setRestaurantes(res);
  }

  // We get the info from the webservices
  const getInfo = (pais_value) => {
    // const user = JSON.parse(ls.get('MCDI'));

    // if (user.rol_id === "2") {
    /************************************************************************
    * Get bigmac sales info
    *************************************************************************/
    client.get(
      `/venta-restaurante/${pais_value}`
    ).then((response) => {
      let data = response.data;

      //TODO remove
      // data = data.map((restaurante) => {
      //   restaurante.total = parseInt(restaurante.total) + (increment * 100);
      //   return restaurante;
      // });

      // let i = Math.floor(Math.random() * data.length);
      // data[i].total = parseInt(data[i].total) + (++increment * 100);
      //TODO remove

      shuffleArray(data, pais_value);

      setTimeout(() => {
        getInfo(paisSuper);
      }, 90000);

      /************************************************************************
    * Get info by hour
    ************************************************************************/
      /************************************************************************
       * Get gran venta
      ************************************************************************/
      client.get(
        `/gran-venta/${pais_value}`
      ).then((response) => {
        let data = response.data;

        let res = { ...granTotal };
        res[pais_value] = data;

        setGranTotal(res);

      }).catch((error) => {
        console.log(error);
      });
      /************************************************************************
       * Get Preventa
      ************************************************************************/
      client.get(
        `/preventa/${pais_value}`
      ).then((response) => {
        let data = response.data;

        let res = { ...preventa };
        res[pais_value] = data;

        setPreventa(res);

      }).catch((error) => {
        console.log(error);
      });
      /************************************************************************
      * Get Preventa
      ************************************************************************/

    }).catch((error) => {
      console.log(error);
    });
    /************************************************************************
    * Get bigmac sales info
    *************************************************************************/
    /************************************************************************
     *Get info by area
    ************************************************************************/
    client.get(
      `/areas/${pais_value}`
    ).then((response) => {
      let data = response.data;

      let historico = {
        gt:
        {
          Mostrador: 109384,
          McDelivery: 58540,
          Auto: 81005,
          Kiosco: 6808,
        },
        hn:
        {
          Mostrador: 2528,
          Domicilio: 1945,
          Auto: 1382,
          Kiosco: 38,
        },
        sv:
        {
          Mostrador: 8078,
          Domicilio: 3061,
          Auto: 5252,
          Kiosco: 513,
        },
        ni:
        {
          Mostrador: 4006,
          Domicilio: 2269,
          Auto: 2063,
          Kiosco: 250,
        },
      }

      let h = historico[pais_value];

      data = data.map((v, i) => {
        v.anterior = typeof h[v.area] === 'undefined' ? 0 : h[v.area];
        return v;
      });

      let res = { ...restaurantes };
      res[pais_value] = data;

      setRestaurantesAreas(res);

    }).catch((error) => {
      console.log(error);
    });
    /************************************************************************
    *Get info by area
    ************************************************************************/
    /************************************************************************
     * Get info by hour
    ************************************************************************/
    client.get(
      `/horas/${pais_value}`
    ).then((response) => {
      let data = response.data;

      if (pais_value === 'hn') {
        // empezamos con 8 am
        data.prev[0].total = '70';
        data.prev[1].total = '189';
        data.prev[2].total = '623';
        data.prev[3].total = '1311';
        data.prev[4].total = '2002';
        data.prev[5].total = '2458';
        data.prev[6].total = '2809';
        data.prev[7].total = '3142';
        data.prev[8].total = '3558';
        data.prev[9].total = '4004';
        data.prev[10].total = '4649';
        data.prev[11].total = '5195';
        data.prev[12].total = '5493';
        data.prev[13].total = '5884';
        data.prev[14].total = '5908';
        data.prev[15].total = '0';
        data.prev[16].total = '0';
      }
      
      if (pais_value === 'gt') {
        // empezamos con 8 am
        data.prev[0].total = '4047';
        data.prev[1].total = '10434';
        data.prev[2].total = '18621';
        data.prev[3].total = '35145';
        data.prev[4].total = '60224';
        data.prev[5].total = '84614';
        data.prev[6].total = '101288';
        data.prev[7].total = '114343';
        data.prev[8].total = '128505';
        data.prev[9].total = '148030';
        data.prev[10].total = '179441';
        data.prev[11].total = '210732';
        data.prev[12].total = '234212';
        data.prev[13].total = '248550';
        data.prev[14].total = '255122';
        data.prev[15].total = '256658';
        data.prev[16].total = '0';
      }

      if (pais_value === 'sv') {
        // empezamos con 8 am
        data.prev[0].total = '0';
        data.prev[1].total = '468';
        data.prev[2].total = '980';
        data.prev[3].total = '2776';
        data.prev[4].total = '5453';
        data.prev[5].total = '7440';
        data.prev[6].total = '8516';
        data.prev[7].total = '9448';
        data.prev[8].total = '10827';
        data.prev[9].total = '12952';
        data.prev[10].total = '15936';
        data.prev[11].total = '18469';
        data.prev[12].total = '19665';
        data.prev[13].total = '20457';
        data.prev[14].total = '20699';
        data.prev[15].total = '20744';
        data.prev[16].total = '0';
      }

      if (pais_value === 'ni') {
        // empezamos con 8 am
        data.prev[0].total = '0';
        data.prev[1].total = '324';
        data.prev[2].total = '819';
        data.prev[3].total = '1736';
        data.prev[4].total = '2903';
        data.prev[5].total = '3783';
        data.prev[6].total = '4385';
        data.prev[7].total = '4974';
        data.prev[8].total = '5688';
        data.prev[9].total = '6663';
        data.prev[10].total = '7849';
        data.prev[11].total = '8968';
        data.prev[12].total = '9655';
        data.prev[13].total = '9957';
        data.prev[14].total = '9966';
        data.prev[15].total = '0';
        data.prev[16].total = '0';
      }

      let res = { ...horas };
      res[pais_value] = data;

      setHoras(res);

      setUpdated(new Date());

    }).catch((error) => {
      console.log(error);
    });
    /************************************************************************
     * Get Canales
    ************************************************************************/
    client.get(
      `/canales/${pais_value}`
    ).then((response) => {
      let data = response.data;

      let res = { ...canales };
      res[pais_value] = data;

      if (pais_value === 'sv') {
        res[pais_value][0].of = 192;
        res[pais_value][0].pv = 33330;
        res[pais_value][0].vd = 22118;
      }
      if (pais_value === 'ni') {
        res[pais_value][0].of = 8002;
        res[pais_value][0].pv = 8698;
        res[pais_value][0].vd = 6120;

      }
      if (pais_value === 'gt') {
        //Oficinas
        res[pais_value][0].of = 70475;
        // venta del día
        res[pais_value][0].pv = 256658;
        // Pre venta
        res[pais_value][0].vd = 125882;
      }

      if (pais_value === 'hn') {
        //Oficinas
        res[pais_value][0].of = 12901;
        // venta del día
        res[pais_value][0].pv = 5908;
        // Pre venta
        res[pais_value][0].vd = 5219;
      }

      setCanales(res);

    }).catch((error) => {
      console.log(error);
    });
    /************************************************************************
    * Get Canales
    ************************************************************************/
    /************************************************************************
     * Get Ventas por delivery
    ************************************************************************/
    client.get(
      `/ventaDelivery/${pais_value}`
    ).then((response) => {
      let data = response.data;

      setVentasDelivery(data);

    }).catch((error) => {
      console.log(error);
    });
    /************************************************************************
    * Get Ventas por delivery
    ************************************************************************/
    // }
  }

  React.useEffect(() => {
    let token = ls.get('MCDI');
    if (token) {
      setLogged(true);
      token = JSON.parse(token);

      // We force logout the users 
      // if (
      //   token.email !== 'yuukan@gmail.com' ||
      //   token.email !== 'marco.gonzalez@iungo.co'
      // ) {
      //   ls.remove('MCDI');
      //   setLogged(false);
      //   return;
      // }


      let paises = token.pais ? token.pais.split(',') : ['gt'];
      paisSuper = paises[0];

      getInfo(paisSuper);
    }
  }, []);

  return (
    <Router>
      <div id="main">
        <Header
          logged={logged}
          setLogged={setLogged}
          logout={logout}
          client={client}
          pais={pais}
          changePais={changePais}
          getInfo={getInfo}
          setPais={setPais}
        />
        <Routes>
          <Route
            index
            element={
              !logged ? (
                <Landing
                  setLogged={setLogged}
                  client={client}
                  signIn={signIn}
                />
              ) : (
                <Home
                  client={client}
                  pais={pais}
                  restaurantes={restaurantes[pais.value]}
                  updated={updated}
                  preventa={preventa[pais.value]}
                  canales={canales[pais.value]}
                  granTotal={granTotal[pais.value]}
                  meta={meta[pais.value]}
                />
              )
            }
          />
          <Route
            path="/horas"
            element={
              <Hora
                client={client}
                horas={horas[pais.value]}
                pais={pais}
                updated={updated}
                preventa={preventa[pais.value] !== null ? preventa[pais.value].preventa : 0}
                meta={meta[pais.value]}
              />
            }
          />
          <Route
            path="/canal"
            element={
              <Canal
                client={client}
                anual={anual[pais.value]}
                canales={canales[pais.value]}
                updated={updated}
                restaurantesAreas={restaurantesAreas[pais.value]}
                restaurantes={restaurantes[pais.value]}
                preventa={preventa[pais.value] !== null ? preventa[pais.value].preventa : 0}
                granTotal={granTotal[pais.value]}
              />
            }
          />
          <Route
            path="/iungo"
            element={
              <Iungo
                updated={updated}
                data={ventasDelivery}
              />
            }
          />
        </Routes>
        {
          logged ?
            <Footer pais={pais} />
            : null
        }
      </div>
    </Router>
  );
}

export default App;
