import apoyo from '../../../src/footer-apoyo.png';
import hn from '../../../src/footer-hn.png';
import ni from '../../../src/footer-ni.png';
import sv from '../../../src/footer-sv.png';
const Footer = (props) => {
    let img;
    switch (props.pais.value) {
        case 'hn':
            img = hn;
            break;
        case 'ni':
            img = ni;
            break;
        case 'sv':
            img = sv;
            break;
        default:
            img = apoyo;
            break;
    }

    return (
        <footer>
            &copy; {new Date().getFullYear()} McDonald’s. Todos los derechos reservados.
            <div className="apoyando">
                <img
                    src={img}
                    alt="Apoyo"
                />

            </div>
        </footer>
    )
}
export default Footer;