import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Legend, ResponsiveContainer, LabelList } from 'recharts';

const Canales = (props) => {

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={300}
                height={300}
                data={props.data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >

                <XAxis dataKey="anio" />
                <YAxis
                    style={{ display: 'none' }}
                />
                <Legend />
                <Bar
                    dataKey="vd"
                    stackId="a"
                    // fill="#648e54"
                    fill="#999999"
                    name="Pre-venta"
                >
                    <LabelList
                        dataKey="vd"
                        position="outsideTop"
                        formatter={(value) => parseInt(value) / 1000 + 'k'}
                        style={{ fill: '#ffffff', fontWeight: 'bold' }}
                    />
                </Bar>
                <Bar
                    dataKey="of"
                    stackId="a"
                    fill="#ffbc0d"
                    name="Oficinas + fundación"
                >
                    <LabelList
                        dataKey="of"
                        position="right"
                        formatter={(value) => parseInt(value) / 1000 + 'k'}
                        style={{ fill: '#000000', fontWeight: 'bold' }}
                    />
                </Bar>
                <Bar
                    dataKey="pv"
                    stackId="a"
                    fill="#db0007"
                    name="Venta del día"
                    radius={[10, 10, 0, 0]}
                >
                    <LabelList
                        dataKey="pv"
                        position="outsideTop"
                        formatter={(value) => parseInt(value) / 1000 + 'k'}
                        style={{ fill: '#ffffff', fontWeight: 'bold' }}
                    />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    )
}
export default Canales;