import React from 'react';

const Areas = (props) => {

    // let perc1 = (props.total - props.anterior) / props.total * 100;
    let perc1 = ((props.total / props.anterior) - 1) * 100;

    let p1 = props.anterior / (props.anterior + props.total) * 100;
    let p2 = props.total / (props.anterior + props.total) * 100;

    const numFormat = (num) => {
        return num.toLocaleString('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
        });
    }

    return (
        <div className="area">
            <div className="bar-container">
                <div className="top"></div>
                <div className="bar">
                    <div className="fill" style={{ height: p1 + '%' }}>
                        <div className="label">
                            {
                                numFormat(props.anterior / 1000) + 'k'
                            }
                        </div>
                    </div>
                </div>
                <div className="number">
                    {props.anteriorText}
                </div>
            </div>
            <div className="bar-container">
                <div className="top">
                    {numFormat(perc1)}%
                </div>
                <div className="bar">
                    <div className="fill" style={{ height: p2 + '%' }}>
                        <div className="label">
                            {
                                (props.total / 1000) > 0.1 ?
                                    numFormat(props.total / 1000) + 'k'
                                    : numFormat(props.total)
                            }
                        </div>
                    </div>
                </div>
                <div className="number">
                    2023
                </div>
            </div>
            <div className="bottom-legend">
                {props.title}
            </div>
        </div>
    )
}
export default Areas;