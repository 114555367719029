import * as React from 'react';
import { BarChart, Bar, XAxis, YAxis, Legend, ResponsiveContainer, LabelList, Cell } from 'recharts';
import Actualizado from './layout/Actualizado';
import descarga from '../../src/descarga.png';
import Papa from 'papaparse';

const Iungo = (props) => {

    const numFormat = (num) => {
        return num.toLocaleString('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
        });
    }

    // COnvert props.data to csv download
    const downloadCSV = () => {
        let csvData = Papa.unparse(props.data);
        // console.log(csvData);
        // Create a link and download the file
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'vetaDelivery.csv';
        a.click();
        window.URL.revokeObjectURL(url);

    }



    // props.data

    return (
        <div className="home-container">
            <div className="bottom-container white">
                <h2>
                    Ventas por Delivery
                    <Actualizado
                        updated={props.updated}
                    />
                </h2>
                <div className="areas-top-container">
                    <div className="left">
                        <div className="chart-container">
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart
                                    width={700}
                                    height={500}
                                    data={props.data}
                                    margin={{
                                        top: 15,
                                        right: 30,
                                        left: 0,
                                        bottom: 5,
                                    }}
                                >

                                    <XAxis dataKey="canal" />
                                    <YAxis />
                                    <Legend />
                                    <Bar
                                        dataKey="ventas"
                                        fill="#ffbc0d"
                                        barSize={window.innerWidth > 768 ? 20 : 10}
                                        radius={[10, 10, 0, 0]}
                                    >
                                        <LabelList
                                            dataKey="ventas"
                                            position="top"
                                            formatter={(ventas) => numFormat(parseInt(ventas) / 1000) + 'k'}
                                        />
                                        {
                                            props.data !== null &&
                                            props.data.map((entry, index) => (
                                                <Cell key={entry.canal} />
                                            ))
                                        }
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>
                <div className="downloadDelivery">
                    <img src={descarga} alt="Descargar" className='download-btn' onClick={downloadCSV} />
                </div>
            </div>
        </div>
    )
}
export default Iungo;