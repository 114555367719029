import * as React from 'react';
import heart from '../../src/heart.png';
import ronald from '../../src/ronald.png';
import emojis from '../../src/emojis.png';
import FlipMove from 'react-flip-move';
import Confetti from 'react-confetti';
import ReactLoading from 'react-loading';
import Actualizado from './layout/Actualizado';
import descarga from '../../src/descarga.png';
import Papa from 'papaparse';


const Home = (props) => {
    const numFormat = (num) => {
        return num.toLocaleString('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        });
    }

    const downloadCSV = (rests) => {
        const csvData = Papa.unparse(rests);

        // Create a link and download the file
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'ventasRestaurantes.csv';
        a.click();
        window.URL.revokeObjectURL(url);
    }

    let total = 0;
    if (props.restaurantes !== null) {
        total = props.restaurantes.reduce(
            (previousValue, currentValue) => parseInt(previousValue) + parseInt(currentValue.total),
            0
        );
    }

    let rests = [];
    if (props.restaurantes !== null && props.preventa !== null) {
        // rests = [...props.restaurantes];

        total = props.restaurantes.reduce(
            (previousValue, currentValue) => parseInt(previousValue) + parseInt(currentValue.total),
            0
        );

        if (total > 0) {
            total += props.preventa.preventa;
            if (props.canales) {
                total += props.canales[1].of;
            }
            for (let i = 0; i < props.restaurantes.length; i++) {
                let found = props.preventa.restaurantes.find(element => element.idTienda === props.restaurantes[i].idTienda);
                // if (found.idTienda === '111') {
                //     console.log(found);
                // }
                if (typeof found !== "undefined") {
                    let obj = {
                        idTienda: props.restaurantes[i].idTienda,
                        tienda: props.restaurantes[i].tienda,
                        total: parseInt(props.restaurantes[i].total) + (parseInt(found.total) || 0)
                    };
                    rests.push(obj);
                } else {
                    rests.push(props.restaurantes[i]);
                }
            }
            rests.sort((a, b) => parseInt(a.total) > parseInt(b.total) ? -1 : 1);
        }

        // We check if the tablero is on pause
        // if (props.granTotal !== null && props.granTotal.tablero_pausado.toLowerCase() === 'si') {
            total = parseFloat(props.granTotal.gran_total);
        // }
    }

    let soFar = (total / props.meta) * 100;
    if (soFar > 100) {
        soFar = 100;
    }

    // We get the max of the array to get the percentage
    let max = 0;
    if (rests !== null) {
        max = Math.max(...rests.map(o => parseInt(o.total)))
    }

    return (
        <div className="home-container">
            <div className="yellow-container">
                <h1>
                    <span className='big'>Big Mac</span><span className="mr">MR</span> vendidos:
                    <Actualizado
                        updated={props.updated}
                    />
                </h1>
                <div className="counter">
                    <div className="numbers">
                        {numFormat(total)}
                    </div>
                </div>
                <div className="emojis">
                    <img src={emojis} alt="Emojis" />
                </div>
                <div className="big-graph-container">
                    {
                        soFar === 100 ?
                            (
                                <Confetti
                                />
                            ) : ""
                    }
                    <div className="big-graph">
                        {
                            rests !== null ?
                                (
                                    <React.Fragment>
                                        <div className="image">
                                            <img src={heart}
                                                alt="Big Graph"
                                                className="main"
                                            />
                                            <div
                                                className="gray"
                                                style={{ height: (100 - soFar) + "%" }}
                                            >
                                                <img src={heart} alt="Big Graph" />
                                            </div>
                                        </div>
                                        <div className="indicator" style={{ top: (100 - soFar) + '%' }}>
                                            <div className="arrow">
                                                <img src={ronald} alt="Ronald Hand" className="ronald" />
                                            </div>
                                            <div className="text">
                                                <div className="big">
                                                    {
                                                        numFormat(soFar)
                                                    }%
                                                </div>
                                                <div className="small">
                                                    de la meta
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ) :
                                (
                                    <center>
                                        <ReactLoading type='spinningBubbles' color='red' height={200} width={200} />
                                    </center>
                                )
                        }
                    </div>
                </div>
            </div>
            <div className="graphic-home">
                <div className="row">
                    <div className="restaurant">
                        <strong>
                            Restaurante
                        </strong>
                    </div>
                    <div className="units">
                        <strong>
                            Unidades
                        </strong>
                        <img src={descarga} alt="Descargar" className='download-home' onClick={() => downloadCSV(rests)} />
                    </div>
                </div>
                {
                    rests !== null ?
                        (
                            <FlipMove
                                duration={500}
                            >
                                {
                                    rests.map((v, i) => {
                                        // We calculate the percentage
                                        let perc = parseInt(v.total) / parseInt(max) * 100;

                                        return (
                                            <div className="row" key={`restaurant${v.idTienda}`}>
                                                <div className="restaurant">
                                                    {
                                                        v.tienda
                                                    }
                                                </div>
                                                <div className="units">
                                                    <div className="fill" style={{ width: `${perc}%` }}></div>
                                                    {
                                                        (props.granTotal === null || props.granTotal.tablero_pausado.toLowerCase() !== 'si') ?
                                                            (
                                                                <div className="number">{numFormat(v.total)}</div>
                                                            ) : ""
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </FlipMove>
                        ) :
                        (
                            <center>
                                <ReactLoading type='spinningBubbles' color='red' height={200} width={200} />
                            </center>
                        )
                }
            </div>
        </div >
    )
}
export default Home;