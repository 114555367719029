const Actualizado = (props) => {

    return (
        <div className="small">
            Actualizado a las&nbsp;
            {('0' + props.updated.getHours()).slice(-2)}:{('0' + props.updated.getMinutes()).slice(-2)}
            {
                parseInt(('0' + props.updated.getHours()).slice(-2)) < 12 ? ' am' : ' pm'
            } ({('0' + props.updated.getDate()).slice(-2)}/{('0' + (props.updated.getMonth() + 1)).slice(-2)}/{props.updated.getFullYear()})
        </div>
    )
}
export default Actualizado;