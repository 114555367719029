import { useState } from 'react';
import { Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import logo from '../logo.png';
import ls from 'localstorage-slim';
ls.config.encrypt = true;

const Landing = (props) => {
    const [state, setState] = useState({
        email: '',
        pass: '',
    });
    const signIn = (e) => {
        e.preventDefault();
        props.signIn(state.email, state.pass);
        setState({
            ...state,
            pass: ''
        });
    }
    const handleChange = (evt) => {
        setState({
            ...state,
            [evt.target.name]: evt.target.value,
        });
    }
    return (
        <div className="login-container">
            <div className="login-block">
                <div className="logo">
                    <img src={logo} alt="McDía feliz" />
                </div>
                <h1>McDía Feliz</h1>
                <Stack
                    component="form"
                    sx={{
                        width: '300px',
                    }}
                    spacing={2}
                    autoComplete="off"
                    onSubmit={signIn}
                >
                    <TextField
                        label="Email"
                        variant="filled"
                        size="small"
                        fullWidth
                        value={state.email}
                        name="email"
                        onChange={handleChange}
                        autoFocus
                        required
                    />
                    <TextField
                        label="Password"
                        variant="filled"
                        size="small"
                        fullWidth
                        value={state.pass}
                        name="pass"
                        onChange={handleChange}
                        type="password"
                        required
                    />
                    <Button
                        variant="contained"
                        endIcon={<SendIcon />}
                        type="submit"
                    >
                        Sign In
                    </Button>

                </Stack>
            </div>
        </div>
    )
}
export default Landing;