import AssessmentIcon from '@mui/icons-material/Assessment';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import { Box, Button, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from "@mui/material";
import MuiAppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import ls from 'localstorage-slim';
import React from "react";
import { Link } from "react-router-dom";
import logo from '../../logo.png';
import gt from '../../gt.png';
import sv from '../../sv.png';
import hn from '../../hn.png';
import ni from '../../ni.png';
import Select from 'react-select'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
ls.config.encrypt = true;
const icon = {
    'gt': gt,
    'sv': sv,
    'hn': hn,
    'ni': ni
}
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    background: '#FFBC0D',
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    width: drawerWidth,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const Header = (props) => {
    ls.config.encrypt = true;

    const [state, setState] = React.useState({
        open: false
    });

    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
        setState({ open: !state.open })
    };

    let options = [
        { value: 'gt', label: 'Guatemala' },
        { value: 'hn', label: 'Honduras' },
        { value: 'sv', label: 'El Salvador' },
        { value: 'ni', label: 'Nicaragua' }
    ];

    React.useEffect(() => {
        let token = ls.get('MCDI');
        if (token) {
            token = JSON.parse(token);
            let paises = token.pais.split(',');
            let pais = options.find(element => element.value === paises[0]);
            props.setPais(pais);
        }
    }, []);

    const token = JSON.parse(ls.get('MCDI'));

    if (token) {
        let paises = token.pais.split(',');
        options = options.filter(item => paises.find(element => element === item.value));
    }

    if (!props.logged) {
        return ""
    } else {
        return (
            <React.Fragment>
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    <AppBar position="fixed" open={state.open}>
                        <Toolbar>
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"

                                aria-label="menu"
                                sx={{
                                    mr: 2,
                                    marginRight: '150px'
                                }}
                                onClick={() => setState({ open: !state.open })}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
                                <img src={logo} alt="" className="logo-header" />
                            </Typography>
                            <Select
                                formatOptionLabel={country => (
                                    <div className="country-option">
                                        <img
                                            src={icon[country.value]}
                                            alt="country"
                                            style={{ width: '20px', marginRight: '5px', verticalAlign: 'middle' }}
                                        />
                                        <span>{country.label}</span>
                                    </div>
                                )}
                                options={options}
                                value={props.pais}
                                styles={
                                    {
                                        control: (base) => ({
                                            ...base,
                                            color: 'black',
                                            width: '150px'
                                        }),
                                        menu: (base) => ({
                                            ...base,
                                            color: 'black',
                                            width: '150px'
                                        }),
                                    }
                                }
                                onChange={
                                    (e) => {
                                        props.changePais(e);
                                        props.getInfo(e.value);
                                    }
                                }
                            />
                            <Button
                                className="logout"
                                color={'inherit'}
                                onClick={props.logout}
                            >
                                <ExitToAppIcon />
                            </Button>
                        </Toolbar>
                    </AppBar>
                </Box>
                <Drawer
                    anchor="left"
                    open={state.open}
                    onClose={() => setState({ open: false })}
                >
                    <DrawerHeader>
                        <IconButton onClick={() => setState({ open: false })}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <List>
                        <ListItem
                            component={Link} to="/"
                            disablePadding
                            sx={{ color: 'text.primary' }}
                            selected={selectedIndex === 0}
                            onClick={(event) => handleListItemClick(event, 0)}
                        >
                            <ListItemButton>
                                <ListItemIcon>
                                    <RestaurantIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary='Por Tiendas'
                                />
                            </ListItemButton>
                        </ListItem>
                        <ListItem
                            component={Link} to="/horas"
                            disablePadding
                            sx={{ color: 'text.primary' }}
                            selected={selectedIndex === 2}
                            onClick={(event) => handleListItemClick(event, 2)}
                        >
                            <ListItemButton>
                                <ListItemIcon>
                                    <AccessTimeIcon />
                                </ListItemIcon>
                                <ListItemText primary='Por horas' />
                            </ListItemButton>
                        </ListItem>
                        <ListItem
                            component={Link} to="/canal"
                            disablePadding
                            sx={{ color: 'text.primary' }}
                            selected={selectedIndex === 3}
                            onClick={(event) => handleListItemClick(event, 3)}
                        >
                            <ListItemButton>
                                <ListItemIcon>
                                    <AssessmentIcon />
                                </ListItemIcon>
                                <ListItemText primary='Por canal' />
                            </ListItemButton>
                        </ListItem>
                        {
                            parseInt(token.rol_id) === 2 ?
                                (
                                    <ListItem
                                        component={Link} to="/iungo"
                                        disablePadding
                                        sx={{ color: 'text.primary' }}
                                        selected={selectedIndex === 4}
                                        onClick={(event) => handleListItemClick(event, 4)}
                                    >
                                        <ListItemButton>
                                            <ListItemIcon>
                                                <AttachMoneyIcon />
                                            </ListItemIcon>
                                            <ListItemText primary='Ventas por Delivery' />
                                        </ListItemButton>
                                    </ListItem>
                                ) : ""
                        }
                    </List>
                </Drawer>
            </React.Fragment>
        )
    }
}
export default Header;