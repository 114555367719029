import * as React from 'react';
import Actualizado from './layout/Actualizado';
import descarga from '../../src/descarga.png';
import Papa from 'papaparse';

const Hora = (props) => {
    let csvData = [];
    const numFormat = (num) => {
        return parseFloat(num).toLocaleString('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        });
    }

    const downloadCSV = () => {
        csvData = Papa.unparse(csvData);
        // console.log(csvData);
        // Create a link and download the file
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'ventasHora.csv';
        a.click();
        window.URL.revokeObjectURL(url);

    }

    let maxPrev = props.horas ? props.horas.prev.reduce((max, curren) => parseFloat(max.total) > parseFloat(curren.total) ? max : curren, 0) : 0;
    let maxNow = props.horas ? props.horas.now.reduce((max, curren) => parseFloat(max.total) > parseFloat(curren.total) ? max : curren, 0) : 0;

    let years = [];
    if (props.horas) {
        for (let i = parseInt(props.horas.min); i < 25; i++) {

            let prev = props.horas.prev.find((item) => parseInt(item.hora) === i);
            let now = props.horas.now.find((item) => {
                // console.log(parseInt(item.hora) === i, parseInt(item.hora), i);
                return parseInt(item.hora) === i;
            });

            let hora = i;

            let percPrev = 0;
            let percNow = 0;
            let ptotal = 0;
            if (typeof prev !== 'undefined') {
                // percPrev = (prev.total / maxPrev.total) * 100;
                percPrev = (prev.total / props.meta) * 100;
                ptotal = prev.total;
            }
            if (typeof now !== 'undefined') {
                // percNow = ((parseFloat(now.total) + parseFloat(props.preventa)) / (parseFloat(maxNow.total) + parseFloat(props.preventa))) * 100;
                // percNow = ((parseFloat(now.total) + parseFloat(props.preventa)) / (parseFloat(props.meta) + parseFloat(props.preventa))) * 100;
                percNow = ((parseFloat(now.total)) / (parseFloat(props.meta))) * 100;
            } else {
                if (i === props.horas.min) {
                    // percNow = (parseFloat(props.preventa) / (parseFloat(props.meta) + parseFloat(props.preventa))) * 100;
                    percNow = 0;
                }
            }

            if (
                i >= parseInt(props.horas.min)
            ) {
                years.push(
                    <div className="row" key={i}>
                        <div className="prev">
                            <div className="quantity">
                                {
                                    numFormat(ptotal)
                                }
                            </div>
                            <div className="bar">
                                <div className="fill gray"></div>
                                <div className="fill" style={{ 'width': percPrev + '%' }}></div>
                            </div>
                        </div>
                        <div className="middle">
                            <div className="yellow">
                                {hora}
                            </div>
                        </div>
                        <div className="today">
                            <div className="bar">
                                <div className="fill gray"></div>
                                <div className="fill" style={{ 'width': percNow + '%' }}></div>
                            </div>
                            <div className="quantity">
                                {
                                    now ?
                                        // numFormat(
                                        //     parseFloat(now.total) + parseFloat(props.preventa)
                                        // )
                                        numFormat(
                                            parseFloat(now.total)
                                        )
                                        :
                                        0
                                }
                            </div>
                        </div>
                    </div>
                );
                csvData.push({
                    'Hora': hora,
                    'Año anterior': ptotal,
                    // 'Año actual': now ? parseFloat(now.total) + parseFloat(props.preventa) : 0
                    'Año actual': now ? parseFloat(now.total) : 0
                });
            }
        }
    }

    return (
        <div className="home-container">
            <hr />
            <div className="indicators">
                <div className="left">
                    <h2>
                        Hora a hora:
                        <div className="small">
                            Comparativo vs año anterior: <br />
                            <Actualizado
                                updated={props.updated}
                            />
                        </div>
                    </h2>
                    <div className="years">
                        <div className="row">
                            <div className="prev">
                                <strong>
                                    Año {props.horas ? props.horas.anteriorText : ''}
                                </strong>
                            </div>
                            <div className="middle"></div>
                            <div className="today">
                                <strong>
                                    Año 2023
                                </strong>
                            </div>
                        </div>
                        {
                            years
                        }
                    </div>
                    <div className="downloadHoras">
                        <img src={descarga} alt="Descargar" className='download-btn' onClick={downloadCSV} />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Hora;