import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Legend, ResponsiveContainer, LabelList, Cell } from 'recharts';

const Anual = (props) => {
    const numFormat = (num) => {
        return num.toLocaleString('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
        });
    }

    // let total = 0;
    // if (props.restaurantes !== null) {
    //     total = props.restaurantes.reduce(
    //         (previousValue, currentValue) => parseInt(previousValue) + parseInt(currentValue.total),
    //         0
    //     );
    //     if (total > 0) {
    //         total += props.preventa;
    //         if (props.canales !== null) {
    //             total += props.canales[1].of;
    //         }
    //         props.data[props.data.length - 1].cantidad = total;
    //     }
    // }

    if(props.granTotal!==null){
        props.data[props.data.length - 1].cantidad = props.granTotal.gran_total;
    }else{
        props.data[props.data.length - 1].cantidad = 0;
    }

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={500}
                height={300}
                data={props.data}
                margin={{
                    top: 15,
                    right: 30,
                    left: 0,
                    bottom: 5,
                }}
            >

                <XAxis dataKey="anio" />
                <YAxis />
                <Legend />
                <Bar
                    dataKey="cantidad"
                    fill="#ffbc0d"
                    // Set bar size 20 for desktop and 10 for mobile
                    barSize={window.innerWidth > 768 ? 20 : 10}
                    radius={[10, 10, 0, 0]}
                >
                    <LabelList
                        dataKey="cantidad"
                        position="top"
                        formatter={(value) => numFormat(parseInt(value) / 1000) + 'k'}
                    />
                    {
                        props.data.map((entry, index) => (
                            <Cell key={props.data[index].anio} fill={props.data[index].anio === "2023" ? '#db0007' : '#ffbc0d'} />
                        ))
                    }
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    )
}
export default Anual;