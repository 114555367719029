import * as React from 'react';
import Anual from './Anual';
import Areas from './Areas';
import Canales from './Canales';
import Actualizado from './layout/Actualizado';
import descarga from '../../src/descarga.png';
import Papa from 'papaparse';

const Canal = (props) => {

    const downloadCSVAnual = () => {
        const csvData = Papa.unparse(props.anual);
        console.log(csvData);
        // Create a link and download the file
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Anual.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
    }

    const downloadCSVCanales = () => {

        let csvData = Papa.unparse(props.canales);
        csvData = csvData
            .replace(/anio/g, 'Año')
            .replace(/pv/g, 'Preventa')
            .replace(/of/g, 'Oficinas + Fundación')
            .replace(/vd/g, 'Venta del día');
        // console.log(csvData);
        // Create a link and download the file
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'ventasPorCanalesComparacion.csv';
        a.click();
        window.URL.revokeObjectURL(url);
    }

    const downloadCSVAreas = () => {
        let csvData = Papa.unparse(props.restaurantesAreas);

        csvData = csvData
            .replace(/area/g, 'Area')
            .replace(/total/g, 'Total Actual')
            .replace(/anterior/g, 'Total Anterior')
            .replace(/aneriorText/g, 'Año anterior');
        // console.log(csvData);
        // Create a link and download the file
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'areasDeNegocio.csv';
        a.click();
        window.URL.revokeObjectURL(url);
    }

    let areas = [];
    let mostrador = 0;
    if (props.restaurantesAreas) {
        areas = props.restaurantesAreas.filter((area) => {
            return (
                area.area === 'Mostrador' ||
                area.area === 'Domicilio' ||
                area.area === 'Auto' ||
                area.area === 'McDelivery' ||
                area.area === 'Kiosco'
            );
        });

        for (let i = 0; i < props.restaurantesAreas.length; i++) {
            if (
                !(
                    props.restaurantesAreas[i].area === 'Mostrador' ||
                    props.restaurantesAreas[i].area === 'Domicilio' ||
                    props.restaurantesAreas[i].area === 'Auto' ||
                    props.restaurantesAreas[i].area === 'McDelivery' ||
                    props.restaurantesAreas[i].area === 'Kiosco'
                )
            ) {
                mostrador += parseFloat(props.restaurantesAreas[i].total);
            }
        }

        // We find the mostrador indnex
        // let mos = areas.findIndex((e) => e.area === 'Mostrador');
        // if (mos !== -1) {
        //     areas[mos].total += mostrador;
        // }
    }

    return (
        <div className="home-container">
            <div className="indicators">
                <div className="left">
                    <h2>
                        Histórico 10 años
                        <Actualizado
                            updated={props.updated}
                        />
                    </h2>
                    <div className="chart-container">
                        <Anual
                            data={props.anual}
                            restaurantes={props.restaurantes}
                            preventa={props.preventa}
                            canales={props.canales}
                            granTotal={props.granTotal}
                        />

                    </div>
                    <div className="descarga-btn">
                        <img src={descarga} alt="Descargar" className='download-btn' onClick={downloadCSVAnual} />
                    </div>
                </div>
                <div className="right">
                    <h2>
                        Canales
                        <Actualizado
                            updated={props.updated}
                        />
                    </h2>
                    <div className="chart-container">
                        <Canales
                            data={props.canales}
                        />
                    </div>
                    <div className="descarga-btn">
                        <img src={descarga} alt="Descargar" className='download-btn' onClick={downloadCSVCanales} />
                    </div>
                </div>
            </div>
            <div className="bottom-container">
                <h2>
                    Áreas de negocio
                    <Actualizado
                        updated={props.updated}
                    />
                </h2>
                <div className="areas-top-container">
                    <div className="areas-container">
                        {
                            areas ?
                                areas.map((area, i) => {
                                    return (
                                        <Areas
                                            key={i}
                                            anterior={area.anterior}
                                            total={area.total}
                                            title={area.area}
                                            anteriorText={area.anteriorText}
                                        />
                                    )
                                })
                                : ""
                        }
                    </div>
                    <div className="descarga-btn">
                        <img src={descarga} alt="Descargar" className='download-btn' onClick={downloadCSVAreas} />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Canal;